import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRequestData } from 'ws-scripts/modules/common';

import { setHasData } from '../actions/hasdata';
import { setIncentives } from '../actions/incentives';
import { setPersonalized } from '../actions/personalized';

const usePrePromos = () => {
	const dispatch = useDispatch();
	const { windowId } = useRequestData();
	const [prePromos, setPrePromos] = useState({
		promos: [],
		incentives: [],
		isLoading: true
	});

	useEffect(() => {
		const fetchData = async () => {
			const promoJson = await window.DDC.WidgetData['ws-specials'][
				windowId
			].preloadedPromos;
			const { promos, incentives, personalized } = promoJson;

			setPrePromos({
				promos,
				incentives,
				isLoading: false
			});
			dispatch(setPersonalized(personalized));
			dispatch(setHasData(true));
			dispatch(setIncentives(incentives));
		};
		fetchData();
		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);

	return [prePromos];
};

export { usePrePromos };
