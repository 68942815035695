import React from 'react';
import { useLabels, usePrefs, useFlags } from 'wsm-common-data';
import PropTypes from 'prop-types';
import templates from '../../templates';
import { isMobile4x3 } from '../../utilities/aspect';
import '../../global-sass/index.scss';
import { aR } from '../../global-constants';
import { useURLPrefs } from '../../hooks/useURLPrefs';
import { promoPropTypes } from '../../utilities/commonPropTypes';

const PromoTypeLayer = ({
	promoIndex,
	ctaLabel,
	ctaUrl,
	discountDisplay,
	incentive = {},
	isMobile,
	promo,
	width,
	triggerPrint,
	vehicleBackgroundImage,
	modelOfferBackgroundImage
}) => {
	const {
		showIncentiveTitleCondition,
		showIncentiveTitleBodystyle,
		useIncentiveShortTitle
	} = usePrefs();
	const { collection, aspectRatio } = useURLPrefs();
	const labels = useLabels();
	const flags = useFlags();
	const { type, couponCode, imageUrl, vehicleImages, globalVehicleId } =
		promo;
	const ctaButtonData = { ctaUrl, ctaLabel };
	const isMobile4x3Template = isMobile4x3(aspectRatio);
	const updatedAR = isMobile4x3Template ? aR.MOBILE_4X3 : aspectRatio;
	const isIncentive =
		typeof incentive === 'object' &&
		incentive !== null &&
		Object.keys(incentive).length > 0 &&
		incentive.constructor === Object;
	const { description, title } = isIncentive ? incentive : promo;
	let applicableVehicle;
	let promoTitle;

	// Feature flag to on/off incentive Make (ex. Land Rover)
	const hideIncentiveMake = flags['hide-incentive-make-ws-special'];

	if (isIncentive) {
		const { condition, error, shortTitle, trims, vehicleTrims } = incentive;

		if (
			globalVehicleId &&
			!error &&
			vehicleTrims &&
			vehicleTrims.length &&
			trims &&
			trims.length
		) {
			let trim;

			// Get the first vehicleTrimId in the list for the promo's globalVehicleId (incentive's vehicleTrims.vehicleId)
			const vehicleTrim = vehicleTrims.find(
				(val) => val.vehicleId === globalVehicleId
			);
			if (
				vehicleTrim &&
				vehicleTrim.vehicleTrimIds &&
				vehicleTrim.vehicleTrimIds.length
			) {
				const vehicleTrimId = vehicleTrim.vehicleTrimIds[0];
				if (vehicleTrimId) {
					// Get the trim information matching the vehicleTrimId
					trim = trims.reduce((acc, val) => {
						if (
							val.globalVehicleTrimIds &&
							val.globalVehicleTrimIds.includes(vehicleTrimId)
						) {
							return val;
						}
						return acc;
					}, null);
				}
			}

			if (trim) {
				// Give priority to incentive title or shortTitle, depending on preference
				promoTitle = useIncentiveShortTitle === 'true'
					? shortTitle || title
					: title || shortTitle;

				// Create vehicle description for the title
				applicableVehicle =
					trim.year && trim.make && trim.model
						? `${trim.year} ${trim.make} ${trim.model}`
						: '';
				if (hideIncentiveMake && applicableVehicle) {
					applicableVehicle = `${trim.year} ${trim.model}`;
				}

				// Turn on additional title data with preferences
				if (showIncentiveTitleCondition === 'true') {
					applicableVehicle = `${labels.get(
						condition
					)} ${applicableVehicle}`;
				}
				if (showIncentiveTitleBodystyle === 'true') {
					applicableVehicle = `${applicableVehicle} ${trim.bodyStyle}`;
				}
			}
		} else {
			return null;
		}
	} else {
		promoTitle = title;
	}

	const Template = templates[collection][updatedAR];
	if (Template) {
		return (
			<Template
				applicableVehicle={applicableVehicle}
				promoIndex={promoIndex}
				ctaButtonData={ctaButtonData}
				couponCode={couponCode}
				description={description}
				discountDisplay={discountDisplay}
				imageUrl={imageUrl}
				isMobile={isMobile}
				incentive={incentive}
				promo={promo}
				title={promoTitle}
				type={type}
				width={width}
				vehicleImages={vehicleImages}
				triggerPrint={triggerPrint}
				vehicleBackgroundImage={vehicleBackgroundImage}
				modelOfferBackgroundImage={modelOfferBackgroundImage}
			/>
		);
	} else {
		return null;
	}
};

PromoTypeLayer.propTypes = {
	promoIndex: PropTypes.number,
	ctaLabel: PropTypes.string,
	ctaUrl: PropTypes.string,
	discountDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	incentive: PropTypes.shape({}),
	isMobile: PropTypes.bool.isRequired,
	promo: PropTypes.shape(promoPropTypes).isRequired,
	width: PropTypes.number,
	triggerPrint: PropTypes.func.isRequired,
	vehicleBackgroundImage: PropTypes.string,
	modelOfferBackgroundImage: PropTypes.string
};

PromoTypeLayer.defaultProps = {
	promoIndex: 1,
	ctaLabel: '',
	ctaUrl: '',
	discountDisplay: '',
	incentive: {},
	width: 890,
	vehicleBackgroundImage: '',
	modelOfferBackgroundImage: ''
};

export default PromoTypeLayer;
