import { g } from '../global-constants';
import { trackEvent } from './tracking';
import { getPlacement } from './aspect';

export const getDestinationLink = ({
	condition,
	label,
	url,
	inventoryCount,
	isCustomAsset,
	labels,
	sitemap,
	type
}) => {
	let retUrl;
	let retLabel;
	let fixedOpsButtonFunction;
	let defaultLabel;
	let defaultUrl;

	switch (type) {
		case g.EVENT:
			// TODO: Handle default button label/url
			// see v9/viewmodel/components/promotions/event/Controller.groovy
			retLabel = label === 'default' ? '' : labels.get(label);
			retUrl = url === 'default' ? '' : url;
			break;
		case g.PARTS:
		case g.SERVICE:
			// TODO: For fixed ops, in CMS web we have pref (buttonFunction) to show details modal or form, (or print if on mobile)
			// here it is hardcoded as 'fixedOpsButtonFunction' with form value
			// see v9/viewmodel/components/promotions/fixed_ops/Controller.groovy
			fixedOpsButtonFunction = 'COMPOSER_PROMOTION_LEAD';
			defaultLabel =
				fixedOpsButtonFunction === 'COMPOSER_PROMOTION_LEAD'
					? labels.get('REQUEST_MORE_INFO')
					: labels.get('OFFER_DETAILS');
			defaultUrl =
				fixedOpsButtonFunction === 'COMPOSER_PROMOTION_LEAD'
					? sitemap.get('PROMOTIONS_FORM_FRAGMENT')
					: sitemap.get('PROMOTIONS_DETAILS_FRAGMENT');
			if (url === '/page-unavailable.htm' && !defaultUrl) {
				if (type === g.SERVICE) {
					defaultUrl = sitemap.get('SERVICE_DEFAULT');
				} else if (type === g.PARTS) {
					defaultUrl = sitemap.get('PARTS_DEFAULT');
				}
			}
			retLabel =
				!label || label === 'default'
					? defaultLabel
					: labels.get(label);
			retUrl =
				!url || url === 'default' || url === '/page-unavailable.htm'
					? defaultUrl
					: url;
			break;
		case g.VEHICLE:
		case g.INCENTIVE:
			// Vehicle promos should always have a url, created by the backend
			// defaultUrl should only be needed for incentive promos
			defaultLabel = labels.get('VIEW_[NUMBER]_QUALIFYING_VEHICLE(S)');
			defaultLabel = defaultLabel.replace(
				'[number]',
				`${inventoryCount}`
			);

			defaultUrl =
				condition === 'CPO'
					? sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_USED')
					: sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_NEW');

			retLabel =
				!label ||
				label === 'default' ||
				label === 'VIEW_[NUMBER]_QUALIFYING_VEHICLE(S)'
					? defaultLabel
					: labels.get(label);
			retUrl = !url || url === 'default' ? defaultUrl : url;
			break;
		default:
		// no-op
	}

	return {
		url: retUrl,
		label: retLabel,
		isFullSlideLink: isCustomAsset && retUrl
	};
};

export const getClickHandler = (type, data) => {
	if (type === 'Asset') {
		const {
			widgetName,
			windowId,
			promo,
			aspectRatio,
			personalized,
			promoIndex
		} = data;
		return ($event) => {
			const { target } = $event;
			if ($event.target && target.classList.contains('custom-asset')) {
				const event = {
					widgetName,
					windowId,
					event: 'specialsEvent',
					specialType: 'click',
					specialCategory:
						promo.type.charAt(0) +
						promo.type.slice(1).toLowerCase(),
					specialId: promo.id,
					specialPlacementType: getPlacement(aspectRatio),
					specialPersonalized: personalized,
					specialAutoGen: promo.autoGenerated,
					nonInteractive: false,
					promoIndex,
					customAsset: true,
					oemId: promo.oemId,
					title: promo.title
				};
				trackEvent(event);
			}
		};
	} else {
		return () => {};
	}
};
