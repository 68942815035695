import Parse from 'html-react-parser';
// Function get incentive by incentiveId
const getIncentive = (incentiveId, incentives) => {
	let trimsMaps = {};

	const incentive = incentives && incentives.length && incentives?.find(
		(item) => item.incentiveId === incentiveId
	);
	incentive?.trims?.forEach((trim) => {
		trim?.globalVehicleTrimIds?.forEach((val) => {
			trimsMaps = {
				...trimsMaps,
				[val]: {
					bodyStyle: trim.bodyStyle,
					trim: trim.trim,
				},
			};
		});
	});
	return trimsMaps;
};

// Function get incentive trim by globalVehicleTrimIds
export const getIncentiveTrimDescriptions = (promo, incentives) => {
	const { globalVehicleTrimIds, incentiveId } = promo;
	if (!globalVehicleTrimIds) {
		return [];
	}

	const trimsMaps = getIncentive(incentiveId, incentives);
	// loop through the list of gvTrimIds and get the string
	return globalVehicleTrimIds
		.map((gvtId) => {
			const gvTrim = trimsMaps[gvtId];
			return `${gvTrim?.trim} ${gvTrim?.bodyStyle}`;
		})
		.filter((v, i, arr) => {
			// dedupe the values in the array
			return arr.indexOf(v) === i;
		});
};

export const parseText = (str) => {
	return Parse(`${str.replace(/\n/g, '<br>')}`);
};
