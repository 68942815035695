import React from 'react';
import { formatCurrency } from 'ddc-intl';
import NoImage from '../components/NoImage';
import OptimizedImage from '../components/OptimizedImage';
import { stringToHash } from './utility';
import { aR } from '../global-constants';

export const getVehicleImages = (
	images,
	containerWidth,
	isMobile = false,
	percentWidth = 0,
	noImageProps = {},
	className = null,
	isCoupon = false
) => {
	const numImages = images.length;
	if (!numImages || numImages === 0) {
		const {
			title = '',
			subtitle = '',
			containerStyles = {},
			containerClasses = [],
			aspectRatio
		} = noImageProps;
		return (
			<NoImage
				title={title}
				subtitle={subtitle}
				containerClasses={containerClasses}
				containerStyles={containerStyles}
				aspectRatio={aspectRatio}
			/>
		);
	}

	const getWidth = (imgIndex) => {
		let imagePercentWidth = '25';

		if (numImages === 1) {
			if (percentWidth) {
				imagePercentWidth = percentWidth;
			} else {
				imagePercentWidth = isMobile ? 40 : 45;
			}
		}

		if (numImages === 2) {
			imagePercentWidth = isMobile || isCoupon ? 30 : 35;
		}

		// Middle image should be a little larger when we have 3 images
		if (numImages === 3 && imgIndex === 1) {
			imagePercentWidth = isCoupon ? 30 : 35;
		}

		return imagePercentWidth;
	};

	const getStyles = (imgIndex) => {
		const imageStyles = {};

		if (numImages === 1) {
			imageStyles.margin = 'auto';
			imageStyles.zIndex = '50';
		}

		if (numImages === 2) {
			imageStyles.margin = 'auto 2%';
			imageStyles.zIndex = '50';
		}

		if (numImages === 3) {
			// TODO: imgIndex === 0 should be in the center (minor fix)
			switch (imgIndex) {
				case 0:
					imageStyles.margin = 'auto 0 auto auto';
					imageStyles.zIndex = '50';
					break;
				case 1:
					// Larger image should appear "out front"
					imageStyles.zIndex = '100';
					imageStyles.margin = 'auto 5px';
					break;
				case 2:
					imageStyles.margin = isCoupon
						? 'auto auto auto 0'
						: 'auto 20px auto 0';
					imageStyles.zIndex = '50';
					break;
				default:
				// no-op
			}
		}

		imageStyles.boxShadow = '0 0 10px #000';

		return imageStyles;
	};

	// TODO: Vehicle title needs to be added to data and used here for alt text
	// Hash of URL is used for key because we have no unique value to associate with the image
	const renderedImages =
		images &&
		images.map((url, i) => {
			const imagePercentWidth = getWidth(i);
			const imageStyles = getStyles(i);
			imageStyles.width = `${imagePercentWidth}%`;

			return (
				<OptimizedImage
					alt=""
					key={stringToHash(url)}
					src={url}
					className={className}
					style={imageStyles}
					width={Math.floor(
						(containerWidth * imagePercentWidth) / 100
					)}
				/>
			);
		});

	return renderedImages;
};

export const getIncentiveImage = (
	image,
	containerWidth,
	altText = '',
	isMobile = false,
	percentWidth = 0,
	noImageProps = {}
) => {
	let renderedImage = null;
	let imagePercentWidth;

	if (!image || image === '') {
		const {
			title = '',
			subtitle = '',
			containerClasses = [],
			containerStyles = {},
			aspectRatio = ''
		} = noImageProps;
		return (
			<NoImage
				title={title}
				subtitle={subtitle}
				containerClasses={containerClasses}
				containerStyles={containerStyles}
				aspectRatio={aspectRatio}
			/>
		);
	}

	if (image) {
		if (percentWidth > 0) {
			imagePercentWidth = percentWidth;
		} else {
			imagePercentWidth = isMobile ? '50' : '65';
		}

		renderedImage = (
			<OptimizedImage
				alt={altText}
				className="custom-asset"
				src={image}
				style={{
					margin: '0 auto',
					width: `${imagePercentWidth}%`,
					zIndex: '100'
				}}
				width={Math.floor((containerWidth * imagePercentWidth) / 100)}
			/>
		);
	}

	return renderedImage;
};

export const getDiscountDisplay = (
	locale,
	discountType,
	discountValue,
	labels,
	reduceDollarSignSize
) => {
	const offLabel = labels.get('OFF');
	const reduceDollar = reduceDollarSignSize === 'true';
	let formattedDiscountValue;
	let discountDisplay;
	const splitString = (index, value) => [
		value.slice(0, index),
		value.slice(index)
	];

	switch (discountType) {
		case 'PERCENT':
			discountDisplay = `${discountValue}% ${offLabel}`;
			break;
		case 'AMOUNT':
			{
				formattedDiscountValue = formatCurrency(
					discountValue,
					locale,
					true
				);

				const formattedValue = splitString(1, formattedDiscountValue);

				discountDisplay = (
					<div>
						<sup
							style={
								reduceDollar
									? { fontSize: '38%', top: '-1em' }
									: null
							}
						>
							{formattedValue[0]}
						</sup>
						{formattedValue[1]}
						{` ${offLabel}`}
					</div>
				);
			}
			break;
		case 'FIXED':
			{
				formattedDiscountValue = formatCurrency(
					discountValue,
					locale,
					true
				);
				const formattedValue = splitString(1, formattedDiscountValue);

				discountDisplay = (
					<div>
						<sup
							style={
								reduceDollar
									? { fontSize: '38%', top: '-1em' }
									: null
							}
						>
							{formattedValue[0]}
						</sup>
						{formattedValue[1]}
					</div>
				);
			}
			break;
		default:
			discountDisplay = null;
			break;
	}

	return discountDisplay;
};
export const getFormattedDollarSign = (text, reduceDollar) => {
	if (typeof text !== 'string') return text;
	const supClass = reduceDollar === 'true' ? 'reduced-dollar-sign' : '';
	let formattedValue = text;
	const stringSplit = text.split('$');
	const dollarMarkup = <sup className={supClass}>$</sup>;

	if (stringSplit.length > 1) {
		const index = 1;
		const result = [...stringSplit];
		let currentPosition = 0;
		for (let i = 0; i < Math.floor(stringSplit.length / index - 1); i++) {
			currentPosition += index;
			result.splice(currentPosition + i, 0, dollarMarkup);
		}
		formattedValue = <>{result}</>;
	}
	return formattedValue;
};

export const getNoImageLabels = (labels, inventoryCount) => {
	return {
		title:
			inventoryCount > 0
				? labels.get('NEW_ARRIVAL')
				: labels.get('NO_MATCHING_VEHICLES'),
		subtitle: inventoryCount > 0 ? labels.get('PHOTO_COMING_SOON') : null
	};
};

export const shouldShowDetailsButton = (
	promo,
	incentive,
	aspectRatio,
	enableAppleWallet,
	url,
	enablePrint
) => {
	// Only display the button if we have a page fragment to link it to
	// thats what 'url' arg is refering to
	const isIphone =
		navigator.userAgent.includes('iPhone') &&
		navigator.userAgent.includes('AppleWebKit');
	const showAppleWalletBtn = enableAppleWallet === 'true' && isIphone;
	const hasCTA =
		promo.destinationUrl !== undefined &&
		promo.destinationLabel !== undefined;
	const showDetailsBtn =
		(promo.additionalDetails !== undefined ||
			promo.disclaimer !== undefined ||
			(incentive !== undefined &&
				incentive !== null &&
				Object.keys(incentive).length !== 0) ||
			(hasCTA && aspectRatio === aR.MOBILE_6X1) ||
			showAppleWalletBtn) &&
		url;

	return (showDetailsBtn || promo.couponCode || enablePrint === 'true') && promo.type && promo.id;
};

export const shouldCustomAssetDisclaimerBtnBeOnTop = (aspectRatio) => {
	return (
		aspectRatio === aR.DESKTOP_4X3 ||
		aspectRatio === aR.MOBILE_4X3_SLIDESHOW ||
		aspectRatio === aR.MOBILE_4X3_COUPON ||
		aspectRatio === aR.DESKTOP_4X1
	);
};


export const getPhoneNumber = (phoneList, labels) => {
	let phoneNumbers = [];
	if(!phoneList) return '';
	phoneNumbers = phoneList.map(phoneItem => {
		return `<div>${labels.get(phoneItem.label)}: ${phoneItem.number}</div>`
	})
	return phoneNumbers?.join(' ')
}
