import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import AspectRatioBox from '../aspect-ratio-box/AspectRatioBox';
import OptimizedImage from '../OptimizedImage';
import { aR as ASPECT_RATIO } from '../../global-constants';
import { isProgramAspectRatioAccount } from '../../utilities/aspect';
import { getQueryStringValue } from '../../utilities/utility';
import { useURLPrefs } from '../../hooks/useURLPrefs';

const CustomAssetPromo = ({ isMobile = false, promo = {}, width }) => {
	const { aspectRatio } = useURLPrefs();
	const labels = useLabels();
	const isFullCouponCaro = useSelector(
		(state) => state.general?.isCouponCaroFullSlide
	);
	const { title, customAssets } = promo;
	const imageUrl = customAssets[aspectRatio];
	const account = useSelector((state) => state.account);
	const programAspectRatioAccount = isProgramAspectRatioAccount(account);
	const isSlideshow =
		aspectRatio === ASPECT_RATIO.DESKTOP_4X1 ||
		aspectRatio === ASPECT_RATIO.MOBILE_4X3_SLIDESHOW;
	const isFixedProgramAspectRatio =
		(programAspectRatioAccount === 'mazda' ||
			programAspectRatioAccount === 'stellantis') &&
		isSlideshow;
	const isAdaptiveHeightEnabled =
		isSlideshow &&
		(programAspectRatioAccount === 'bmw' ||
			programAspectRatioAccount === 'mini');

	let altText = getQueryStringValue(imageUrl, 'altText');
	if (altText) {
		altText = decodeURIComponent(altText);
	}
	const imgUrl = new URL(imageUrl);
	let customAssetContent;

	const optImgContent = (
		<>
			<OptimizedImage
				className={`custom-asset ${
					!isMobile && isFullCouponCaro ? 'p-3' : ''
				} ${
					promo.destinationUrl === 'NO_DESTINATION_URL'
						? 'no-destination-url'
						: ''
				}`}
				style={
					isFixedProgramAspectRatio
						? { position: 'relative', zIndex: 2, height: 'auto' }
						: {}
				}
				alt={altText || title}
				src={imgUrl.href}
				width={width}
			/>
			<p className="sr-only">
				{promo?.ctaConfig?.openInNewTab
					? labels.get('OPEN_IN_NEW_TAB')
					: labels.get('OPEN_IN_SAME_TAB')}
			</p>
		</>
	);

	if (isAdaptiveHeightEnabled) {
		customAssetContent = optImgContent;
	} else {
		customAssetContent = (
			<AspectRatioBox
				innerWrapperStyle={
					isFixedProgramAspectRatio
						? { backgroundImage: `url(${imageUrl})` }
						: { display: 'flex' }
				}
			>
				{isFixedProgramAspectRatio ? (
					<>
						<div className="fixed-program-aspect-ratio-blurred-box" />
						{optImgContent}
					</>
				) : (
					optImgContent
				)}
			</AspectRatioBox>
		);
	}

	return customAssetContent;
};

CustomAssetPromo.propTypes = {
	isMobile: PropTypes.bool,
	promo: PropTypes.shape({}).isRequired,
	width: PropTypes.number.isRequired
};

export default CustomAssetPromo;
