/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels, usePrefs } from 'wsm-common-data';
import { Modal } from 'react-bootstrap';
import { getDiscountDisplay } from '../utilities/general';
import { g } from '../global-constants';
import { formatPerpetualDates } from '../utilities/utility';
import { parseText } from '../utilities/incentive';
import {
	appleWalletComponentProps,
	ctaComponentProps,
	promoPropTypes
} from '../utilities/commonPropTypes';

const DetailsModal = ({
	isOpen,
	isMobile,
	onModalClose,
	promo,
	cta,
	applePassBtn,
	ctaPrint,
	isDisclosure = false
}) => {
	const labels = useLabels();
	const { locale } = useRequestData();
	const { reduceDollarSignSize } = usePrefs();
	const {
		additionalDetails = '',
		couponCode = '',
		description = '',
		disclaimer = '',
		discountType,
		discountValue,
		endDateDisplay = '',
		startDateDisplay = '',
		title = '',
		type,
		modalTitle
	} = promo;
	let discount = null;

	if ([g.PARTS, g.SERVICE].includes(type) && discountType !== 'NO_DISCOUNT') {
		discount = getDiscountDisplay(
			locale,
			discountType,
			discountValue,
			labels,
			reduceDollarSignSize
		);
	}

	useEffect(() => {
		// Refreshing our ui-dialog module
		if (typeof window !== 'undefined' && isMobile) {
			window.DDC.mobile.slidein.init();
		}
	}, [isMobile]);

	const ctaClick = () => {
		if (promo?.ctaConfig?.type === 'LEAD') {
			onModalClose('CTA');
		}
	};

	const onKeydown = (e) => {
		if (promo?.ctaConfig?.type === 'LEAD' && e.key === 'Enter') {
			onModalClose('CTA');
		}
	};

	return (
		<Modal
			show={isOpen}
			onHide={onModalClose}
			className={`m-1 ${isMobile ? 'dock-right' : 'details-modal'}`}
		>
			<Modal.Header>
				<Modal.Title className="text-center" tabIndex="1">
					<span className="h3 my-0 px-5">
						{modalTitle ? (
							labels.get(modalTitle)
						) : (
							<>
								{title} {discount}
							</>
						)}
					</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					dangerouslySetInnerHTML={{
						__html: formatPerpetualDates(
							startDateDisplay,
							endDateDisplay,
							labels
						)
					}}
				/>
				<p tabIndex="1">{description}</p>
				{cta !== null && (
					<div
						onClick={ctaClick}
						onKeyDown={onKeydown}
						role="presentation"
					>
						{cta}
					</div>
				)}
				{applePassBtn !== null && <>{applePassBtn}</>}
				{ctaPrint !== null && <>{ctaPrint}</>}
				{additionalDetails ? (
					<>
						<h4 className="mt-2 mb-0 h5" tabIndex="1">
							{labels.get('DETAILS')}
						</h4>
						<p tabIndex="1">{parseText(`${additionalDetails}`)}</p>
					</>
				) : null}
				{couponCode ? (
					<>
						<h4 className="mt-2 mb-0 h5" tabIndex="1">
							{labels.get('COUPON_CODE')}
						</h4>
						<p tabIndex="1">{couponCode}</p>
					</>
				) : null}
				{disclaimer ? (
					<>
						<h4 className="mt-2 mb-0 h5" tabIndex="1">
							{isDisclosure
								? labels.get('DISCLOSURE')
								: labels.get('DISCLAIMER')}
						</h4>
						<div className="text-muted small">
							<p tabIndex="1">{parseText(`${disclaimer}`)}</p>
						</div>
					</>
				) : null}
			</Modal.Body>
			<button
				type="button"
				aria-label="Close"
				className="close text-muted close-modal"
				onClick={onModalClose}
				tabIndex="1"
			>
				<i className="ddc-icon ddc-icon-close" aria-hidden="true" />
			</button>
		</Modal>
	);
};

DetailsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isMobile: PropTypes.bool.isRequired,
	onModalClose: PropTypes.func.isRequired,
	promo: PropTypes.shape(promoPropTypes),
	cta: PropTypes.shape(ctaComponentProps),
	applePassBtn: PropTypes.shape(appleWalletComponentProps),
	ctaPrint: PropTypes.shape({
		pushOutsiderCtaPrint: PropTypes.func
	}),
	isDisclosure: PropTypes.bool
};

export default DetailsModal;
