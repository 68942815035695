import React, { Fragment, useRef } from 'react';
import { setClassNames } from 'ddc-classnames-js';
import {
	useLabels,
	useRequestData,
	useSitemap,
	usePrefs
} from 'wsm-common-data';
import AspectRatioBox from '../components/aspect-ratio-box/AspectRatioBox';
import DetailsButton from '../components/DetailsButton';
import CtaButton from '../components/CtaButton';
import CtaPrint from '../components/CtaPrint';
import { aR, g } from '../global-constants';
import { templatePropTypes } from '../utilities/commonPropTypes';
import {
	getIncentiveImage,
	getNoImageLabels,
	getVehicleImages,
	shouldShowDetailsButton,
	getFormattedDollarSign
} from '../utilities/general';
import { IMAGE_BREAKPOINTS } from '../utilities/images';
import OptimizedImageBackground from '../components/OptimizedImageBackground';
import { useURLPrefs } from '../hooks/useURLPrefs';

export const offerDetailsFragment = ({
	couponCode,
	cta,
	promoIndex,
	description,
	// details refer to button details, is not string type
	details,
	discountDisplay,
	labels,
	title
}) => (
	<Fragment>
		{title ? <h2 tabIndex={promoIndex + 1}>{title}</h2> : null}
		{discountDisplay && (
			<div
				tabIndex={promoIndex + 2}
				className="font-weight-bold text-capitalize discount"
			>
				{discountDisplay}
			</div>
		)}
		{description ? <p tabIndex={promoIndex + 3}>{description}</p> : null}
		<div className="cta-wrapper">
			{cta}
			{couponCode ? (
				<div className="d-flex align-items-center details">
					<div className="flex-fill text-nowrap">{details}</div>
					<div
						className="coupon-code flex-fill justify-self-right text-right font-weight-normal"
						tabIndex={promoIndex + 4}
					>
						{`${labels.get('COUPON_CODE')}: ${couponCode}`}
					</div>
				</div>
			) : (
				details
			)}
		</div>
	</Fragment>
);

export const offerDetails10x1Fragment = ({
	couponCode,
	cta,
	// details refer to button details, is not string type
	details,
	promoIndex,
	labels
}) => (
	<Fragment>
		<div className="cta-wrapper">
			{cta}
			{couponCode ? (
				<div className="d-flex flex-column">
					<div className="text-nowrap" tabIndex={promoIndex + 4}>
						{details}
					</div>
					<div
						className="coupon-code justify-self-right text-right font-weight-normal"
						tabIndex={promoIndex + 5}
					>
						{`${labels.get('COUPON_CODE')}: ${couponCode}`}
					</div>
				</div>
			) : (
				details
			)}
		</div>
	</Fragment>
);

export const detailsButtonFragment = ({
	btnStyle,
	promoIndex,
	detailsButtonClasses = [],
	incentive = {},
	applicableVehicle = '',
	promo = {},
	cta = {},
	ctaPrint
}) => {
	return (
		<DetailsButton
			promoIndex={promoIndex}
			btnStyle={btnStyle}
			classes={detailsButtonClasses}
			incentive={incentive}
			applicableVehicle={applicableVehicle}
			listingConfigId=""
			promo={promo}
			cta={cta}
			ctaPrint={ctaPrint}
		/>
	);
};

export const ctaButtonFragment = ({
	promoIndex,
	ctaButtonClasses = [],
	ctaModalButtonClasses = [],
	ctaButtonData = {},
	promo = {}
}) => {
	const { ctaLabel, ctaUrl } = ctaButtonData;
	const classes = ctaModalButtonClasses.length
		? ctaModalButtonClasses
		: ctaButtonClasses;

	return ctaUrl && ctaLabel ? (
		<CtaButton
			promoIndex={promoIndex}
			ctaButtonClasses={classes}
			label={ctaLabel}
			url={ctaUrl}
			promo={promo}
		/>
	) : null;
};


export const ctaPrintFragment = ({ pushOutsiderCtaPrint, promo }) => {
	return <CtaPrint pushOutsiderCtaPrint={pushOutsiderCtaPrint} promo={promo}/>
};

export const Mobile6x1Fragment = ({
	promoIndex,
	promo,
	title,
	incentive = {},
	isSlant,
	ctaButtonData,
	triggerPrint
}) => {
	const ctaModalButtonClasses = setClassNames(['my-4']);
	const { reduceDollarSignSize } = usePrefs();
	const template = (
		<div
			className={setClassNames([
				'wrapper',
				'px-4',
				'py-3',
				isSlant && 'blue-background'
			])}
		>
			<span className="ddc-icon ddc-icon-tag d-block" />
			{title ? (
				<h2 className="title" tabIndex={promoIndex + 1}>
					{getFormattedDollarSign(title, reduceDollarSignSize)}
				</h2>
			) : null}
			<div className="cta-wrapper">
				{detailsButtonFragment({
					promoIndex,
					promo,
					incentive,
					aspectRatio: aR.MOBILE_6X1,
					cta: ctaButtonFragment({
						promoIndex,
						ctaModalButtonClasses,
						ctaButtonData,
						promo
					}),
					ctaPrint: ctaPrintFragment({
						promo,
						pushOutsiderCtaPrint: triggerPrint,
					}),
				})}
			</div>
		</div>
	);

	return <AspectRatioBox>{template}</AspectRatioBox>;
};

Mobile6x1Fragment.propTypes = templatePropTypes;

// This fragment is shared between Desktop4x3 and Mobile4x3-slideshow/Mobile4x3-coupon
export const Shared4x3Fragment = ({
	applicableVehicle,
	promoIndex,
	couponCode = '',
	ctaButtonData,
	description,
	discountDisplay = '',
	imageUrl,
	offerDetails,
	promo,
	incentive = {},
	title,
	type,
	vehicleImages = [],
	width,
	triggerPrint,
	vehicleBackgroundImage,
	modelOfferBackgroundImage
}) => {
	let template;
	const labels = useLabels();
	const ctaButtonClasses = setClassNames(['btn-inverse', 'd-table']);
	const detailsButtonClasses = ['p-0', 'text-decoration-underline'];
	const noImageLabels = getNoImageLabels(labels, promo.inventoryCount);
	const ctaModalButtonClasses = setClassNames(['my-4']);
	const { aspectRatio, enableCarousel, collection } = useURLPrefs();
	const { enableAppleWallet, reduceDollarSignSize, enablePrint } = usePrefs();
	const { deviceType } = useRequestData();
	const isMobile = deviceType === 'MOBILE';
	const couponRef = useRef();
	const isSlant = collection.toLowerCase() === 'slant';
	const tabIndexBase = promoIndex * 10;
	const isCouponCaro = enableCarousel === 'true' && !isMobile;
	const sitemap = useSitemap();
	const url = sitemap.get('PROMOTIONS_DETAILS_FRAGMENT');

	const hasDetailsButton = shouldShowDetailsButton(
		promo,
		incentive,
		aspectRatio,
		enableAppleWallet,
		url,
		enablePrint
	);

	const sharedFragment = (
		<Fragment>
			{isSlant ? (
				<div className="stripe w-100 position-relative" />
			) : null}
			<div className="d-flex flex-column justify-content-between text">
				<div className="text-data-upper">
					{title ? (
						<h2 tabIndex={tabIndexBase + 1}>
							{getFormattedDollarSign(
								title,
								reduceDollarSignSize
							)}
						</h2>
					) : null}
					{description ? (
						<p tabIndex={tabIndexBase + 2}>
							{getFormattedDollarSign(
								description,
								reduceDollarSignSize
							)}
						</p>
					) : null}
				</div>
				<div
					className={`text-data-lower d-flex ${
						!hasDetailsButton ? 'details-padding' : ''
					}`}
				>
					<div className="text-data-left text-left">
						<div className="cta-wrapper">
							{ctaButtonFragment({
								promoIndex,
								ctaButtonClasses,
								ctaButtonData,
								promo
							})}
							{hasDetailsButton
								? detailsButtonFragment({
										promoIndex,
										detailsButtonClasses,
										promo,
										cta: ctaButtonFragment({
											promoIndex,
											ctaModalButtonClasses,
											ctaButtonData,
											promo
										}),
										ctaPrint: ctaPrintFragment({
											promo,
											pushOutsiderCtaPrint: triggerPrint,
										}),
								  })
								: null}
						</div>
					</div>
					<div className="text-data-right text-right">
						{discountDisplay && (
							<div
								className="font-weight-bold text-capitalize discount"
								tabIndex={tabIndexBase + 8}
							>
								{discountDisplay}
							</div>
						)}
						{couponCode && (
							<div
								className="coupon-code"
								tabIndex={tabIndexBase + 9}
							>
								{`${labels.get('COUPON_CODE')}: ${couponCode}`}
							</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);

	if ([g.PARTS, g.SERVICE, g.EVENT].includes(type)) {
		template = (
			<Fragment>
				<OptimizedImageBackground
					className="image w-100"
					style={{
						backgroundImage: `url("${imageUrl}")`,
						backgroundColor: 'black'
					}}
					width={IMAGE_BREAKPOINTS[1]}
				/>
				{sharedFragment}
			</Fragment>
		);
	} else if (type === g.VEHICLE) {
		template = (
			<Fragment>
				<OptimizedImageBackground
					className="d-flex flex-row justify-content-center flex-shrink-0 align-middle image"
					style={{
						backgroundImage: `url("${vehicleBackgroundImage}")`,
						backgroundPosition: 'bottom'
					}}
					width={IMAGE_BREAKPOINTS[1]}
					key={`${promo.id}-container`}
				>
					{getVehicleImages(
						vehicleImages,
						width,
						isMobile,
						30,
						{
							title: noImageLabels.title,
							subtitle: noImageLabels.subtitle
						},
						'vehicle-image',
						true
					)}
				</OptimizedImageBackground>
				{sharedFragment}
			</Fragment>
		);
	} else if (type === g.INCENTIVE) {
		template = (
			<Fragment>
				<OptimizedImageBackground
					className="d-flex flex-row justify-content-center flex-shrink-0 align-middle image"
					style={{
						backgroundImage: `url("${modelOfferBackgroundImage}")`,
						backgroundPosition: 'bottom'
					}}
					width={IMAGE_BREAKPOINTS[1]}
				>
					{getIncentiveImage(
						imageUrl,
						width,
						'', // leave empty unless we have a label other than YMMT
						isMobile,
						45,
						collection.toLowerCase() === 'slant' ||
							collection.toLowerCase() === 'fade'
							? {
									title: noImageLabels.title,
									subtitle: noImageLabels.subtitle,
									containerStyles: {
										zIndex: 50,
										boxShadow: 'rgb(0 0 0) 0px 0px 10px'
									}
							  }
							: {
									title: noImageLabels.title,
									subtitle: noImageLabels.subtitle
							  }
					)}
				</OptimizedImageBackground>
				{isSlant ? (
					<div className="stripe w-100 position-relative" />
				) : null}
				<div className="d-flex flex-column justify-content-between text">
					<div className="text-data-upper">
						{applicableVehicle || title ? (
							<h2 tabIndex={tabIndexBase + 1}>
								{applicableVehicle}
								<span className="pt-1 font-weight-normal w-100 d-block">
									{getFormattedDollarSign(
										title,
										reduceDollarSignSize
									)}
								</span>
							</h2>
						) : null}
						{description ? (
							<p tabIndex={tabIndexBase + 2}>
								{getFormattedDollarSign(
									description,
									reduceDollarSignSize
								)}
							</p>
						) : null}
						{offerDetails ? (
							<p tabIndex={tabIndexBase + 3}>
								{getFormattedDollarSign(
									offerDetails,
									reduceDollarSignSize
								)}
							</p>
						) : null}
					</div>
					<div
						className={`text-data-lower d-flex ${
							!hasDetailsButton ? 'details-padding' : ''
						}`}
					>
						<div className="cta-wrapper">
							{ctaButtonFragment({
								promoIndex,
								ctaButtonClasses,
								ctaButtonData,
								promo
							})}
							{hasDetailsButton
								? detailsButtonFragment({
										promoIndex,
										detailsButtonClasses,
										promo,
										incentive,
										applicableVehicle,
										cta: ctaButtonFragment({
											promoIndex,
											ctaModalButtonClasses,
											ctaButtonData,
											promo
										}),
										ctaPrint: ctaPrintFragment({
											promo,
											pushOutsiderCtaPrint: triggerPrint,
										}),
								  })
								: null}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	return (
		<AspectRatioBox>
			<div
				ref={couponRef}
				className={setClassNames([
					'd-flex',
					'promo-card',
					'flex-column',
					'h-100',
					'w-100',
					isCouponCaro ? 'carousel p-3' : ''
				])}
			>
				{template}
			</div>
		</AspectRatioBox>
	);
};

Shared4x3Fragment.propTypes = templatePropTypes;
