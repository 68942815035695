import { formatCurrency } from 'ddc-intl';
import { formatPerpetualDates } from './utility';
import { getIncentiveTrimDescriptions } from './incentive';
import { getPhoneNumber } from './general';

const getDiscountDisplay = (
	locale,
	discountType,
	discountValue,
	labels,
	reduceDollarSignSize
) => {

	const offLabel = labels.get('OFF');
	const reduceDollar = reduceDollarSignSize === 'true';
	let formattedDiscountValue;
	let discountDisplay;
	const splitString = (index, value) => [
		value.slice(0, index),
		value.slice(index)
	];
	const fontSize = 'font-size: 38%; top: -1em;';
	const discountDisplayStyle = reduceDollar ? fontSize : null;
	switch (discountType) {
		case 'PERCENT':
			discountDisplay = `${discountValue}% ${offLabel}`;
			break;
		case 'AMOUNT':
			{
				formattedDiscountValue = formatCurrency(
					discountValue,
					locale,
					true
				);

				const formattedValue = splitString(1, formattedDiscountValue);
				discountDisplay = (
					`<div>
						<sup style=${discountDisplayStyle}>
							${formattedValue[0]}
						</sup>
						${formattedValue[1]}
						${offLabel}
					</div>`
				);
			}
			break;
		case 'FIXED':
			{
				formattedDiscountValue = formatCurrency(
					discountValue,
					locale,
					true
				);
				const formattedValue = splitString(1, formattedDiscountValue);

				discountDisplay = (
					`<div>
						<sup style=${discountDisplayStyle}>
						>
							${formattedValue[0]}
						</sup>
						${formattedValue[1]}
					</div>`
				);
			}
			break;
		default:
			discountDisplay = null;
			break;
	}

	return discountDisplay;
};

export const printViewOnChromeIos = (promo, labels, locale, incentives, account) => {
	const printWindow = window.open('', '_blank');

	const { 
		phoneNumbers = [],
		dealerInfo: {
			name: dealershipName = '',
			state = '',
			postalCode = '',
			address1 = '',
			address2 = '',
			address3 = '',
			city = '',
		} = {}
	} = account;

	let discount = '';
	let incentive;
	let effectiveDates = '';
	if (['PARTS', 'SERVICE'].includes(promo.type) && promo.discountType !== 'NO_DISCOUNT') {
		discount = getDiscountDisplay(locale, promo.discountType, promo.discountValue, labels);
	}

	if (promo.type === 'INCENTIVE' && promo.incentiveId && incentives && incentives.length) {
		incentive = incentives?.find((item) => item.incentiveId === promo.incentiveId);
		effectiveDates = `${labels.get('OFFER_ONLY_VALIDATE')} ${incentive?.effectiveDate} ${labels.get('THROUGH')} ${incentive?.expirationDate}`;
	}
	
	const endDate= promo.type !== 'INCENTIVE' ? formatPerpetualDates(promo.startDateDisplay, promo.endDateDisplay, labels) : effectiveDates;
	const title = promo.type !== 'INCENTIVE' ? promo.title : incentive?.title;
	const offerDetailsField = promo.type === 'INCENTIVE' && incentive?.offerDetails;
	const disclaimerField = promo.type !== 'INCENTIVE' ? promo.disclaimer : incentive?.disclaimer;
	const trimsField = promo.type === 'INCENTIVE' && promo.globalVehicleTrimIds && getIncentiveTrimDescriptions(promo, incentives) || [];
	printWindow.document.write(`<html>
	<head>
		<title>Print</title>
		<style>

			.print-view-ios {
				padding: 60px 40px 40px 40px;
			}

			.start-end-date {
				font-weight: 400;
				font-size: 12.25px;
				line-height: 18px;
			}
	
			.heading,
			.discount,
			.applicable-trims,
			.disclaimer-title {
				font-weight: 600;
				font-size: 17.5px;
				line-height: 22px;
				padding-top: 8px;
				padding-bottom: 4px;
			}
	
			.heading {
				padding: 8px 0px;
				padding-top: 0;
			}

			.discount {
				padding: 8px 0;
			}

			.description,
			.offer-details {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				padding-top: 4px;
				padding-bottom: 4px;
			}
	
			.additional-details,
			.coupon-code {
				font-weight: 400;
				font-size: 12.25px;
				line-height: 18px;
				padding: 4px 0;
			}
	
			.disclaimer-title {
				padding: 18px 0;
			}

			.coupon,
			.details {
				font-weight: 600;
			}

			.dealership {
				font-size: 24px;
				font-weight: bold;
				margin: 0 0 4px 0;
			}
		
			.address1 {
				margin-top: 8px;
			}
		
			.phone {
				margin-top: 8px;
			}

			.underline {
				width: 30%;
				border-bottom: 1px solid;
				margin: 20px 0;
			}

		</style>
	</head>
	<body>`);
	printWindow.document.write(
	`<div class='print-view-ios' style='font-family: Arial'>
		<div class='dealership'>${dealershipName}</div>
		${address1 ? `<div class='address1'>${address1}. ${city}, ${state} ${postalCode}</div>` : ''}
		${address2 ? `<div class='address2'>${address2}. ${city}, ${state} ${postalCode}</div>` : ''}
		${address3 ? `<div class='address3'>${address3}. ${city}, ${state} ${postalCode}</div>` : ''}
		${phoneNumbers?.length ? `<div class='phone'>${getPhoneNumber(phoneNumbers, labels)}</div>` : ''}
		<hr class='underline'/>
		<div class='heading'>${title}</div>
		<div class='start-end-date'>${endDate}</div>
		${offerDetailsField ? `<div class='offer-details'>${offerDetailsField}</div>` : ''}

		${promo?.description ? `<div class='description'>${promo.description}</div>` : ''}

		${discount ? `<div class='discount'>${discount}</div>` : ''}

		${ promo?.additionalDetails ? `<div class='additional-details'>
		 	<div class='h5 details' style='font-weight: bold;'>${labels.get('DETAILS')}:</div>
			${promo.additionalDetails}
		</div>` : ''}

		${promo?.couponCode ? `<div class='coupon-code'>
			<div class='h5 coupon' style='font-weight: bold;'>${labels.get('COUPON_CODE')}:</div>
			${promo.couponCode}
		</div>` : ''}

		${trimsField.length > 0 ? `<div>
			<div class='applicable-trims'>${labels.get('APPLICABLE_TRIMS')}:</div>
				<div>${trimsField?.join()}</div>
			</div>` : ''
		}
		${
			disclaimerField ? `<div>
				<div class='disclaimer-title'>${labels.get('DISCLAIMERS')}:</div>
				<div class='text-muted small'>${disclaimerField}</div>
			</div>` : ''
		}
	</div>`);
	printWindow.document.write('</body></html>');

	setTimeout(() => {
		printWindow.print();
		printWindow.close();
	}, 100);
};
