import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
	useRequestData,
	usePrefs,
	useLabels,
	useSitemap
} from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { ctaComponentProps } from '../utilities/commonPropTypes';
import { trackEvent } from '../utilities/tracking';
import { getPlacement } from '../utilities/aspect';
import { a, aR } from '../global-constants';
import { setModalState } from '../actions/modalState';

const CtaButton = ({
	promoIndex,
	customAsset = false,
	ctaButtonClasses = [],
	label,
	url,
	promo
}) => {
	let button = null;
	const buttonContainerRef = useRef();
	const labels = useLabels();
	const sitemap = useSitemap();
	const { widgetName, windowId, deviceType } = useRequestData();
	const { aspectRatio } = usePrefs();
	const isCoupon =
		aspectRatio === aR.DESKTOP_4X3 || aspectRatio === aR.MOBILE_4X3_COUPON;
	const tabIndexBase = isCoupon ? promoIndex * 10 : promoIndex;
	const personalized = useSelector((state) => state.personalized);
	const dispatch = useDispatch();
	const leadFormURL = sitemap.get('PROMOTIONS_LEAD_FORM_FRAGMENT');

	const subscribeCloseLeadDialog = async () => {
		if (deviceType === 'DESKTOP') {
			$('body').on('dialogclose', () =>
				dispatch(setModalState({ type: a.CLOSE_MODAL }))
			);
		} else {
			$(document).on('slideClose', () =>
				dispatch(setModalState({ type: a.CLOSE_MODAL }))
			);
		}
	};

	const focusHandling = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();
	};

	const blurHandling = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();
	};

	const onKeydown = (ev) => {
		if (ev.key === 'Enter') {
			ev.preventDefault();
			ev.stopPropagation();
			if (buttonContainerRef.current) {
				const ctaButton =
					buttonContainerRef.current.querySelector('button');
				ctaButton.click();
			}
		}
	};

	const onClick = () => {
		const specialType = url === 'LEAD' ? 'dialog lead form' : 'click';
		if (url === 'LEAD') {
			dispatch(setModalState({ type: a.OPEN_MODAL }));
			subscribeCloseLeadDialog();
		}
		trackEvent({
			widgetName,
			windowId,
			event: 'specialsEvent',
			specialType,
			specialCategory:
				promo.type.charAt(0) + promo.type.slice(1).toLowerCase(),
			specialId: promo.id,
			specialPlacementType: getPlacement(aspectRatio),
			specialPersonalized: personalized,
			specialAutoGen: promo.autoGenerated,
			nonInteractive: false,
			promoIndex,
			customAsset,
			oemId: promo.oemId,
			title: promo.title
		});
	};

	// Only display the button if we have an href and label
	if (url && url !== 'default' && label && label !== 'default') {
		// Set tabindex='-1' to hide offscreen Button from tabbing for A11Y
		// TODO: Need data-promo-tracking... attributes
		if (url === 'LEAD') {
			const leadForm = `${leadFormURL}?promotionId=${promo.id}`;
			button = (
				<Button
					role="button"
					tabIndex={tabIndexBase + 5}
					className={setClassNames(['dialog', ctaButtonClasses])}
					onClick={() => {
						onClick();
					}}
					onBlur={blurHandling}
					onKeyDown={onKeydown}
					onFocus={(e) => focusHandling(e)}
					data-width="375"
					bsStyle="primary"
					bsSize="lg"
					data-title={label}
					data-href={leadForm}
				>
					{label}
					<p className="sr-only">Open Lead form</p>
				</Button>
			);
		} else {
			button = (
				<Button
					className={ctaButtonClasses}
					tabIndex={tabIndexBase + 5}
					onClick={() => {
						onClick();
					}}
					onBlur={blurHandling}
					bsStyle="primary"
					bsSize="lg"
					href={url}
					target={promo?.ctaConfig?.openInNewTab ? '_blank' : '_self'}
				>
					{label}
					<p className="sr-only">
						{label.toLowerCase() === 'request more info' ? (
							<span className="hidden">{promo.title}</span>
						) : null}
						{promo?.ctaConfig?.openInNewTab
							? labels.get('OPEN_IN_NEW_TAB')
							: ''}
					</p>
				</Button>
			);
		}
	}
	return <div ref={buttonContainerRef}>{button}</div>;
};

CtaButton.propTypes = ctaComponentProps;

export default CtaButton;
