import account from './account';
import incentives from './incentives';
import personalized from './personalized';
import testParams from './testparams';
import hasdata from './hasdata';
import positionData from './positionData';
import modal from './modalState';
import general from './generalState';
import vehicleInfo from './vehicleId';

const root = {
	account,
	incentives,
	personalized,
	testParams,
	hasdata,
	positionData,
	modal,
	general,
	vehicleInfo
};

export default root;
